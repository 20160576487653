import React from 'react';
import { graphql } from 'gatsby';
import TermsBlock from '../components/TermsPage/TermsBlock';
import TermsStateAddenda from '../components/TermsPage/TermsStateAddenda';
import { SEO } from '../components/SEO';
import { SanityTerms, SanityStateSpecificTerms } from '../../graphql-types';
import { theme } from '../styles';
import { ThemeProvider } from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';

interface TermsPageTemplateProps {
  location: {
    pathname: string;
  };
  data: {
    sanityTerms: SanityTerms;
    allSanityStateSpecificTerms: {
      edges: Array<{ node: SanityStateSpecificTerms }>;
    };
  };
}

export default function TermsPageModalTemplate({
  data: { sanityTerms, allSanityStateSpecificTerms },
  location: { pathname }
}: TermsPageTemplateProps) {
  const showAddenda =
    pathname.includes('clinical-membership-terms') &&
    allSanityStateSpecificTerms &&
    allSanityStateSpecificTerms.edges &&
    allSanityStateSpecificTerms.edges.length > 0;
  const states = allSanityStateSpecificTerms.edges
    .map(i => i.node)
    .filter(i => i.published);

  const handleClick = () => {
    window.close();
  };

  return (
    <ThemeProvider>
      <div style={{ position: `relative` }}>
        <SEO pageTitle={sanityTerms.title} noindex={true} />
        <CloseButton onClick={() => handleClick()}>X</CloseButton>
        <TermsBlock {...sanityTerms} />
        {showAddenda && states.length > 0 && (
          <TermsStateAddenda states={states} />
        )}
      </div>
    </ThemeProvider>
  );
}

const CloseButton = styled.button`
  background: transparent;
  display: block;
  position: fixed;
  top: ${theme.space[2]}px;
  right: ${theme.space[2]}px;
  border: none;
  color: ${theme.colors.greyMedium};
  font-size: 1.5em;
  cursor: pointer;
  font-weight: 200;

  @media screen and (min-width: ${theme.breakpoints[2]}) {
    top: ${theme.space[5]}px;
    right: ${theme.space[5]}px;
  }
`;

export const query = graphql`
  query TermsPageModalTemplateQuery($id: String!) {
    sanityTerms(id: { eq: $id }) {
      id
      title
      revisedAt(formatString: "MMMM DD, YYYY")
      _rawBody
    }
    allSanityStateSpecificTerms {
      edges {
        node {
          slug {
            current
          }
          state
          published
        }
      }
    }
  }
`;
