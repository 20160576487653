import React from 'react';
import StaticPageContainer from '../StaticPages/StaticPageContainer';
import { SanityStateSpecificTerms } from '../../../graphql-types';
import { H2 } from '@parsleyhealth/cilantro-ui';

interface TermsStateAddendaProps {
  states: SanityStateSpecificTerms[];
}

export default function TermsStateAddenda({ states }: TermsStateAddendaProps) {
  return (
    <StaticPageContainer>
      <div>
        <H2 my="l" textAlign="center">
          State Addenda
        </H2>

        <ul>
          {states
            .sort((a, b) => a.state.localeCompare(b.state))
            .map(({ slug, state }) => (
              <li key={slug.current}>
                <a href={`/${slug.current}`}>{state}</a>
              </li>
            ))}
        </ul>
      </div>
    </StaticPageContainer>
  );
}
